// --------------------------
// BOOTSTRAP
// --------------------------

@import '~bootstrap/scss/bootstrap';

// Slick js
@import '~trumbowyg/dist/ui/trumbowyg';

// --------------------------
// FONTS
// --------------------------

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// --------------------------
// VARIABLES
// --------------------------

// Fonts

$font: 'Roboto', sans-serif;

// Colors

$black-admin: rgba(0, 0, 0, 1);
$black-admin-hover: rgba(0, 0, 0, 0.9);
$white-admin: #ffffff;
$grey-admin: #f2f2f2;
$green-admin: rgba(29, 159, 60, 1);
$green-admin-hover: rgba(29, 159, 60, 0.9);
$red-admin: rgba(227, 52, 47, 1);
$red-admin-hover: rgba(227, 52, 47, 0.9);
$blue-admin: rgba(52, 144, 220, 1);
$blue-admin-hover: rgba(52, 144, 220, 0.9);

// Size

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1480px;

// General

html {font-size: 62.5%;}

body, html {
  font-family: $font;
}

body {
  font-size: 1.5rem;
}

h1, h2 {
  font-family: $font;
  font-size: 2.8rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 2.5rem 0 1.5rem 0;
}

h3 {
  font-family: $font;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 2.5rem 0 1.5rem 0;
}

h4 {
  font-family: $font;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 2.5rem 0 1.5rem 0;
}

img {
  max-width: 100%;
}

// Form

label {
  width: 100%;
  margin-top: 1.5rem;
}

input, textarea, select {
  width: 100%;
  padding: 1.2rem;
  border: 1px solid black;
}

textarea {
  height: 20rem;
}

.form-button {
  width: 100%;
  font-size: 1.5rem;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  margin-top: 1.7rem;
  color: #ffffff;
  display: block;
  text-align: center;
  transition: 0.3s all;

  &:hover {
    color: #ffffff;
    text-decoration: none;
    transition: 0.3s all;
  }

}

.form-button-green {
  border: 1px solid $green-admin;
  background-color: $green-admin;

  &:hover {
    background-color: $green-admin-hover;
  }
}

.form-button-red {
  border: 1px solid $red-admin;
  background-color: $red-admin;

  &:hover {
    background-color: $red-admin-hover;
  }
}

.form-button-blue {
  border: 1px solid $blue-admin;
  background-color: $blue-admin;

  &:hover {
    background-color: $blue-admin-hover;
  }
}

.link-button {
  width: 100%;
  font-size: 1.2rem;
  padding: 0.5rem 2.5rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  transition: 0.3s all;

  &:hover {
    color: #ffffff;
    transition: 0.3s all;
  }
}

input[type=radio], input[type=checkbox] {
  width: 15px;
}

.link-button-modify {

  &:hover {
    background-color: $blue-admin;
  }

}

.link-button-delete {

  &:hover {
    background-color: $red-admin;
  }
}

.alert-ok {
  border: 1px solid $green-admin;
  background-color: $green-admin-hover;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
}

.admin-mode, .container-admin, .html-admin {
  background-color: $black-admin !important;
  height: inherit;
}

.container-admin {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container-principal {
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

/* PAGINATION */

.pagination {
  justify-content: center;
}

.page-link {
  border: none;
  color: $black-admin;
}

.page-item.active .page-link {
  color: #fff;
  background-color: $black-admin;
}

.page-link:hover {
  background-color: #cccccc;
  border-color: $black-admin;
}


// Navigation

.link-menu-admin {
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1rem 0;
  line-height: 1.2rem;

  span {
    font-size:0.9rem;
  }

}

.navbarDropdownAdmin {
  color: white;
}

.navbar-dark {

  line-height: 1.5rem;

  .navbar-nav {
    margin-top: 1.5rem;
  }

  .nav-item {

    padding: 0 1rem;

    .nav-link {
      color: $white-admin;
      text-transform: uppercase;
      font-size: 1.5rem;
    }

    .dropdown-menu {

      .dropdown-item {
        line-height: 2rem;
        font-size: 1.4rem;
      }

    }

  }

  .navbar-log {
    position: absolute;
    right: 1rem;
    top: 0;
    list-style: none;

    .nav-link-admin {
      color: white !important;
      font-size: 1.1rem;
    }

  }

}

.nav-link-admin {
  color: white !important;
}

.admin-second-nav {
  background-color: $grey-admin;
  padding: 2.5rem 1.5rem;
  margin-top: -1.5rem;
  margin-bottom: 3rem;
  text-align: right;
}

.admin-second-nav-retour {
  text-align: left;
}

// Bouton

.btn-admin-add {
  background-color: $green-admin;
  border: none;
  color: white;
  padding: 1.5rem 2.5rem;
}

.btn-admin-add:hover {
  background-color: $green-admin-hover;
  border: none;
  color: white;
}

.btn-admin-update {
  background-color: $blue-admin;
  border: none;
  color: white;
  padding: 1.5rem 2.5rem;
}

.btn-admin-update:hover {
  background-color: $blue-admin-hover;
  border: none;
  color: white;
}

.btn-admin-delete {
  background-color: $red-admin;
  border: none;
  color: white;
  padding: 1.5rem 2.5rem;
}

.btn-admin-delete:hover {
  background-color: $red-admin-hover;
  border: none;
  color: white;
}

.btn-admin-return {
  background-color: $black-admin;
  border: none;
  color: white;
  padding: 1.5rem 2.5rem 1.5rem 2.2rem;
}

.btn-admin-return:hover {
  background-color: $black-admin-hover;
  border: none;
  color: white;
}

.table .thead-admin th {
  color: #fff;
  background-color: $black-admin;
  border-color: $black-admin;
  font-size: 1.1rem;
}

.admin-modifier-svg:hover {
  color: $blue-admin;
}

.admin-supprimer-svg:hover {
  color: $red-admin;
}

.push-home-admin {
  border: 1px solid #000000;

  &:hover {
    background-color: #000000;
  }
}

.push-home-txt-admin {
  min-height: 10rem;
  padding: 3rem;
  font-size: 2rem;
}

// Login

.card {

  font-size: 1.3rem;

  .card-logo {

    text-align: center;

    img {
      max-width: 22rem;
      padding: 3rem 1.5rem;
    }

  }

  a {
    font-size: 1.3rem;
    color: #000000;

    &:hover {
      color: #000000;
    }

  }

  input {
    padding: 1.5rem;
    font-size: 1.3rem;
  }

  button {
    background-color: #000000;
    border:1px solid #000000;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0.5rem 2.5rem;
    transition: all 0.3s;

    &:hover {
      background-color: #ffffff;
      color: #000000;
      border:1px solid #000000;
      transition: all 0.3s;
    }

  }

  .card-body {
    padding: 3.5rem 1.5rem;
  }

}

// Slider

.slide {

  display: flex;
  flex-direction: row;
  width:100%;
  padding: 1rem 0;
  border-bottom: 1px solid $grey-admin;

  div {
    padding: 0 1.5rem;
  }

  .slide-img {
    width: 15rem;
    min-width: 15rem;
    height: 8rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #000000;
  }

  .slide-right {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    max-width: 15rem;

    input {
      padding: 0.3rem;
      text-align: center;
    }
  }

}

@media only screen and (max-width: $screen-md-min) {
  .slide {

    flex-direction: column;

    .slide-right {
      margin-left: 0;
    }

    div {
      width: 100%;
      padding: 1.5rem 0;
    }

  }
}

.img-b-right {
  width: 100%;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: $grey-admin;
}

.delete-img {
  width: 100%;
  text-align: center;

  a {
    font-size: 1.2rem;
    color: $black-admin;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
